<template>
    <div class="main">
        <el-row :gutter="10">
            <el-col :span="8">
                <div class="mobile_menu_preview">
                    <ul class="pre_menu_list">
                        <li
                            class="list_item"
                            :class="{ tab: true }"
                            @click="reactive({ item, index })"
                            v-for="(item, index) in menu.button"
                            :key="index">
                            <div class="tag" :class="{ active: idx == index }">
                                <span class="icon_menu_dot"></span>
                                <span>{{ item.name }}</span>
                            </div>
                            <div class="items" v-if="idx == index">
                                <ul class="menu_box">
                                    <add-item
                                        @edit="sonmenu"
                                        @add="additem"
                                        @drag="dragitem"
                                        :relist="item.sub_button"/>
                                </ul>
                                <i class="arrow_in arrow"></i>
                            </div>
                        </li>
                        <li
                            v-show="isSet(menu.button) ? menu.button.length >= 0 && menu.button.length < 3 : false"
                            class="list_item"
                            @click="addmenu">
                            <i class="menu_add"></i>
                                添加菜单
                        </li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="edit">
                    <div class="bg" style="padding:20px;width:500px;box-sizing: border-box;padding-right:0">
                        <slot name="title"></slot>
                        <slot name="other"></slot>
                    </div>
                    <editor
                        v-if="isedit"
                        :content="edit"
                        :group="idx"
                        :idx="item_idx"
                        @remove="remove"
                        @removeitem="removeitem"
                        @charge="rename">
                    </editor>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import AddItem from "./AddItem";
import Editor from "./editor";
const set = {
    button: [
        {
            type: "click",
            name: "歌手简介",
            key: "V1001_TODAY_SINGER",
            url: "",
            sub_button: [],
        },
        {
            name: "菜单",
            sub_button: [
                {
                    type: "view",
                    name: "搜索",
                    key: "",
                    url: "http://www.soso.com/",
                    sub_button: [],
                },
                {
                    type: "view",
                    name: "视频",
                    key: "",
                    url: "http://v.qq.com/",
                    sub_button: [],
                },
                {
                    type: "click",
                    name: "赞一下我们",
                    key: "V1001_GOOD",
                    url: "",
                    sub_button: [],
                },
            ],
        },
        {
            type: "click",
            name: "我的菜单",
            key: "V1001_TODAY_GXH",
        },
    ],
};
export default {
    data() {
        return {
            idx: null,
            isedit: false,
            item_idx: -1,
            list: [],
            edit: {},
            form: {},
            menu: this.item,
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        showaddgroup() {
            return this.menu.button !== 3;
        },
    },
    methods: {
        save() {
            console.log(this.menu);
        },
        getdata() {
            return this.menu;
        },
        dragitem({ item }) {
            console.log(item);
            this.menu.button[this.idx].sub_button = item;
            // this.$set(this.menu.button[this.idx],'sub_button',item)
            console.log(this.menu.button[this.idx].sub_button);
        },
        addmenu() {
            this.menu.button.push({
                type: "view",
                name: "菜单名称",
                url: "",
                sub_button: [],
            });
            this.idx++;
            this.edit = Object.assign({}, this.menu.button[this.idx]);
        },
        isSet: function(variable) {
            if (typeof variable != "undefined") {
                return true;
            } else {
                return false;
            }
        },
        removeitem({ form, index, group }) {
            let idx = index;
            this.menu.button[group].sub_button.splice(index, 1);
            if (this.menu.button[group].sub_button.length === 0) this.menu.button[group].type = 'view'
            if (index === this.menu.button[group].sub_button.length) idx = index - 1;
            console.log(idx);
            this.item_idx = idx;
            this.edit = Object.assign({}, this.menu.button[group].sub_button[idx]);
        },
        remove({ form, index, group }) {
            // 如果menu列表为空
            if (this.menu.button.length === 0) return;
            if (group !== 0 && group == this.menu.button.length - 1) {
                this.menu.button.splice(group, 1);
                this.idx -= 1;
            } else if (group == 0) {
                this.menu.button.splice(group, 1);
                this.idx = 0;
            } else {
                this.menu.button.splice(group, 1);
            }
            this.edit = Object.assign(this.menu.button[this.idx]);
            // 清空数据
            // if (this.menu.button.length == 0) {
            //     this.activeMenuIndex = '';
            //     this.activeMenuName = '';
            //     this.activeMenuItemIndex = '';
            //     this.activeMenuItemName = '';
            // }
            // this.menu.button[this.idx].sub_button[index].name = form.name
            // this.menu.button[this.idx].sub_button[index].url = form.url
        },
        additem(type) {
            console.log(this.menu.button[this.idx],this.idx);
            delete this.menu.button[this.idx].type
            delete this.menu.button[this.idx].url
            this.menu.button[this.idx].sub_button
                ? this.menu.button[this.idx].sub_button.push(type)
                : this.$set(this.menu.button[this.idx], "sub_button", [type]);
            this.edit = Object.assign({}, type);
            this.item_idx = this.item_idx + 1;
            // (this.menu.button[this.idx].sub_button = [],this.menu.button[this.idx].sub_button.push(type))
        },
        reactive({ item, index }) {
            console.log(index);
            this.edit = Object.assign({}, item);
            console.log(this.edit);
            this.isedit = true;
            this.idx = index;
            this.item_idx = -1;
        },
        sonmenu({ item, index }) {
            console.log(item);
            this.edit = Object.assign({}, item);
            this.item_idx = index;
        },
        rename({ form, index, group }) {
            console.log(form, index);
            // 判断是组还是组项
            if (index == -1) {
                console.log(this.menu.button[this.idx]);
                this.menu.button[this.idx].name = form.name;
                this.menu.button[this.idx].url = form.url;
                return;
            }
            console.log(233);
            this.menu.button[this.idx].sub_button[index].name = form.name;
            this.menu.button[this.idx].sub_button[index].url = form.url;
            console.log(name, index);
        },
    },
    components: {
        AddItem,
        Editor,
    },
};
</script>
<style lang="scss">
    li{
        list-style: none;
    }
    ul{
        padding: 0;
        margin: 0;
    }
</style>
<style lang="scss" scoped>
.menu_add {
    background: url(../images/index_z354723.png) 0 -18px no-repeat;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    display: inline-block;
    margin-top: -2px;
}
.bg{
    background-color: rgb(244,245,249);
}
.edit {
    /* width: auto; */
    padding-left: 20px;
    /* height: 580px; */
    display: inline-block;
}
.tab {
    position: relative;
}
.menu_box {
    border: 1px solid #d0d0d0;
}
.arrow {
    position: absolute;
    left: 50%;
    margin-left: -6px;
}
.arrow_in {
    bottom: -5px;
    display: inline-block;
    line-height: 0;
    width: 0;
    height: 0;
    border-width: 6px;
    border-style: dashed;
    border-color: transparent;
    border-bottom-width: 0;
    border-top-color: #fafafa;
    border-top-style: solid;
}
.items {
    width: 100%;
    background-color: #fafafa;
    border-top-width: 0;
    bottom: 60px;
    position: absolute;
}
.icon_menu_dot {
    background: url(../images/index_z354723.png) 0 -36px no-repeat;
    width: 7px;
    height: 7px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 2px;
    margin-top: -2px;
    *margin-top: 0;
}
.mobile_menu_preview {
    position: relative;
    display: inline-block;
    width: 317px;
    height: 580px;
    background: transparent url(../images/bg_mobile_head_default2968da.png)
        no-repeat 0 0;
    background-position: 0 0;
    border: 1px solid #e7e7eb;
    .pre_menu_list {
        cursor: pointer;
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #e7e7eb;
        background: transparent url(../images/bg_mobile_foot_default2968da.png)
        no-repeat 0 0;
        background-position: 0 0;
        background-repeat: no-repeat;
        padding-left: 43px;
    }
    .active {
        border: 1px solid #44b549;
        color: #44b549;
    }
    .list_item {
        line-height: 50px;
        flex-wrap: nowrap;
        text-align: center;
        flex: 1;
        position: relative;
        /* width: 33.33%; */
        box-sizing: border-box;
        background-color: rgb(250, 250, 250);
        .tag {
            overflow-x: hidden;
            /* overflow: hidden; */
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
</style>
