<template>
    <div class="box">
        <div style="padding:10px;padding-left:10px">
            <el-button type="primary" @click="dialogVisible=true" size="medium">添加模板</el-button>
            <el-button type="primary" @click="isdiy=true" size="medium">添加自定义模板</el-button>
        </div>
        <el-dialog
            title="默认菜单"
            :visible.sync="dialogVisible"
            @close="clear"
            width="1000px">
            <wechat 
                v-if="dialogVisible"
                ref="wechat"
                :item="getdata.data?JSON.parse(getdata.data):itemopen">
                <el-form 
                    :rules="rules"
                    ref="menuset" 
                    slot="title"
                    :model="menuset" 
                    label-width="80px">
                    <el-form-item label="菜单名称" prop="title">
                        <el-input v-model="menuset.title"></el-input>
                    </el-form-item>
                </el-form>
            </wechat>
            <div slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="savemenu">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="自定义菜单"
            :visible.sync="isdiy"
            @close="clear"
            width="1000px"> 
            <wechat 
                v-if="isdiy"
                ref="wechat"
                :item="getdata.data?JSON.parse(getdata.data):itemopen">
                <el-form 
                    :rules="rules"
                    ref="menuset" 
                    :model="menuset" 
                    slot="title"
                    label-width="80px">
                    <el-form-item label="菜单名称" prop="title">
                        <el-input v-model="menuset.title"></el-input>
                    </el-form-item>
                </el-form>
                <el-form ref="form" :model="matchrule" label-width="80px" slot="other">
                    <el-form-item label="性别">
                        <el-select v-model="matchrule.sex" clearable>
                            <el-option label="男" value="1"></el-option>
                            <el-option label="女" value="2"></el-option>
                            <el-option label="全部" value=""></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="粉丝分组" >
                        <el-select v-model="matchrule.tag_id" clearable>
                            <template v-for="item in wxTags">
                                <el-option :label="item.tagName" :value="item.tagId" :key="item.tagId"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="手机系统">
                        <el-select v-model="matchrule.client_platform_type" clearable>
                            <!-- IOS(1), Android(2),Others(3) -->
                            <el-option label="IOS" :value="1"></el-option>
                            <el-option label="Android" :value="2"></el-option>
                            <el-option label="其它" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="位置" clearable>
                        <el-cascader
                            v-model="position"
                            :options="area"
                            @change="handleChange">
                        </el-cascader>
                    </el-form-item>
                </el-form>
            </wechat>
            <div slot="footer">
                <el-button @click="isdiy = false">取 消</el-button>
                <el-button type="primary" @click="savediymenu">确 定</el-button>
            </div>
        </el-dialog>
        
        <el-form ref="form" :model="params" label-width="80px" inline="inline">
            <el-form-item label="菜单状态" size="mini">
                <el-select v-model="params.status" @change="_getwxMenus">
                    <template v-for="(item) in MenuStatus">
                        <el-option :label="item.label" :value="item.value" :key="item.value"></el-option>
                    </template>
                </el-select>
            </el-form-item>
            <el-form-item label="菜单类型" size="mini">
                <el-select v-model="params.type" @change="_getwxMenus">
                    <template v-for="item in MenuType">
                        <el-option 
                            :label="item.label" 
                            :value="item.value" 
                            :key="item.value">
                        </el-option>
                    </template>
                </el-select>
            </el-form-item>
        </el-form>
        <el-table :data="list" @row-dblclick="showdetail">
            <el-table-column label="菜单名字" prop="title"></el-table-column>
            <el-table-column label="菜单状态" prop="status">
                <template >
                    <el-tag type="success">{{!params.status?'未生效':'生效'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" prop="status">
                <template slot-scope="scope">
                    <el-switch 
                    :active-value="1"
                    :inactive-value="0"
                    :disabled="scope.row.type===1&&scope.row.status===1"
                    v-model="scope.row.status" 
                    @change="open(scope.row)"></el-switch>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import wechat from './components/wechat'
import position from '@/assets/weChat/region.json'
import {getwxMenus,setwxMenus,openjwm,chargemenu,stopMenu} from './api/index'
import { provinceAndCityDataPlus,CodeToText } from 'element-china-area-data'
import { mapActions, mapState } from "vuex";
import _ from "lodash";
const menustatus = [
    {value:0,label:'未生效'},
    {value:1,label:'生效'},
]
const status = {
    0:'未生效',
    1:'生效',
}
const menutype = [
    {value:1,label:'默认菜单'},
    {value:2,label:'自定义菜单'}
]
const type = {
    1:'默认菜单',
    2:'自定义菜单'
}
const set = {
    button: [
        {
            type: "view",
            name: "子菜单",
            url: "",
            sub_button: [],
        }
    ],
}
export default {
    data () {
        return {
            dialogVisible:false,
            value:'',
            matchrule: {},
            params:{
                status:1,
                type:1
            },
            options: provinceAndCityDataPlus,
            list:[],
            getdata:{},
            menuset:{},
            isdiy:false,
            rules: {
                title: [
                    { required: true, message: '请输入菜单名称', trigger: 'blur' },
                ],
            }
        }
    },
    created() {
        this._getwxMenus()
        this.getwxtag()
    },
    filters:{
        status(val) {
            return status[val]
        },
        type(val) {
            return type[val]
        }
    },
    components:{
        wechat
    },
    computed:{
        ...mapState(["wxTags"]),
        area() {
            return position
        },
        MenuStatus() {
            return menustatus
        },
        MenuType(){
            return menutype
        },
        itemopen() {
            this.dialogVisible
            const data = _.cloneDeep(set)
            console.log(data)
            return data
        },
        position:{
            get:function() {
                return [this.matchrule.province,this.matchrule.city]
            },
            set:function(val) {
                console.log(val)
                CodeToText[val]
                this.matchrule.country = "中国"
                this.matchrule.province = val[0]
                this.matchrule.city = val[1]
            }
        }
    },
    methods:{
        ...mapActions(["getwxtag"]),
        open(val) {
            console.log(val.jwmId,val.status)
            if(val.status === 1) {
                openjwm(val.jwmId).then(res => {
                    this._getwxMenus()
                    this.getwxtag()
                }).catch(err => {
                    this._getwxMenus()
                    this.getwxtag()
                })
            } else{
                stopMenu(val.jwmId).then(res => {
                    this._getwxMenus()
                    this.getwxtag()
                    console.log(res)
                }).catch(err => {
                    this._getwxMenus()
                    this.getwxtag()
                })
            }
        },
        handleChange(val) {
            console.log(val)
        },
        clear() {
            console.log(233)
            this.getdata = {}
            this.menuset={}
            this.matchrule = {}
        },
        showdetail(item) {
            console.log(item)
            // console.log(JSON.parse(this.getdata.data))
            if(item.type === 2) {
                this.getdata = item
                console.log(JSON.parse(item.data))
                this.matchrule = JSON.parse(item.data).matchrule
                this.menuset.title = item.title
                this.isdiy = true
                return
            }
            this.getdata = item
            this.menuset.title = item.title
            this.dialogVisible = true
        },
        _getwxMenus() {
            getwxMenus(this.params).then(res => {
                this.list = res.data.result.records
                console.log(res.data.result.records)
            })
        },
        savediymenu() {
            console.log(Object.keys(this.matchrule))
            // ["tag_id", "sex"]
            if(Object.keys(this.matchrule).includes("tag_id")){
                console.log('修改')
                if(Object.keys(this.matchrule).length<2) {
                    return this.$message({
                        type:'warning',
                        message:'至少选择一项'
                    })
                }
            }else{
                if(Object.keys(this.matchrule).length<1) {
                    return this.$message({
                        type:'warning',
                        message:'至少选择一项'
                    })
                }
            }
            console.log(this.getdata)
            let data = this.$refs.wechat.getdata()
            data.matchrule = this.matchrule
            this.menuset.type = 2
            let putdata = Object.assign({},this.menuset)
            putdata.data = JSON.stringify(data)
            console.log(putdata.data)
            if(this.getdata.jwmId) {
                chargemenu({jwmId:this.getdata.jwmId,data:putdata}).then(res => {
                    this._getwxMenus()
                    this.getwxtag()
                    this.isdiy = false
                })
            }else{
                setwxMenus(putdata).then(res => {
                    this._getwxMenus()
                    this.getwxtag()
                    this.isdiy = false
                    // this.dialogVisible = false
                })
            }
        
        },
        savemenu() { 
            let data = this.$refs.wechat.getdata()
            this.menuset.type = 1
            let putdata = Object.assign(this.menuset)
            putdata.data = JSON.stringify(data)
            if(this.getdata.jwmId) {
                chargemenu({jwmId:this.getdata.jwmId,data:putdata}).then(res => {
                    this._getwxMenus()
                    this.getwxtag()
                    this.dialogVisible = false
                })
            }else{
                setwxMenus(putdata).then(res => {
                    this._getwxMenus()
                    this.getwxtag()
                    this.dialogVisible = false
                })
            }
        }
    }
}
</script>

<style>

</style>