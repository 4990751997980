<template>
    <div class="addcontain">
        <div class="drag" ref="dragTable">
            <template v-for="(item, index) in relist">
                <li
                    :key="index"
                    class="item item_drag"
                    :class="{ active: actidx == index }"
                    @click.stop="edit({ item, index })">
                    <span :class="{ item_top: index === 0 }">{{ item.name }}</span>
                </li>
            </template>
        </div>
        <li
            class="item"
            :class="{ item_add: isshow === 0 }"
            @click.stop="addmunu"
            v-show="isshow !== 5">
            <i class="add"></i>
        </li>
    </div>
</template>

<script>
import { menutype } from "./utils";
import Sortable from "sortablejs";
import _ from "lodash";
export default {
    props: {
        relist: {
        type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            list: [],
            actidx: null,
        };
    },
    created() {
        // this.getList();
    },
    watch: {
        relist(val) {
            console.log(val)
            if (this.actidx >= val.length) return (this.actidx = val.length - 1);
        },
    },
    computed: {
        isshow() {
            return this.relist.length;
        },
    },
    methods: {
        addmunu() {
            let type = new menutype();
            this.actidx = this.relist.length;
            this.$emit("add", type);
        },
        edit(item) {
            this.actidx = item.index;
            this.$emit("edit", item);
        },
        async getList() {
            this.$nextTick(() => {
                this.setSort();
            });
        },
        setSort() {
            console.log(this.$refs.dragTable)
            const el = this.$refs.dragTable;
            this.sortable = Sortable.create(el, {
                animation: 150,
                ghostClass: "blue-background-class",
                setData: function(dataTransfer) {
                    // to avoid Firefox bug
                    dataTransfer.setData('Text', "");
                    // Detail see : https://github.com/RubaXa/Sortable/issues/1012
                    // console.log(dataTransfer)
                },
                onEnd: evt => {
                    let newval = _.cloneDeep(this.relist)
                    const targetRow = newval.splice(evt.oldIndex, 1)[0]
                    console.log(newval,evt.newIndex, 0, targetRow)
                    newval.splice(evt.newIndex, 0, targetRow)
                    console.log(newval)
                    this.$emit('drag',{item:newval})
                    // // // for show the changes, you can delete in you code
                    // const tempIndex = newval.splice(evt.oldIndex, 1)[0]
                    // newval.splice(evt.newIndex, 0, tempIndex)
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.addcontain {
    width: 100%;
    font-size: 14px;
}

.item {
    line-height: 44px;
    height: 44px;
    width: 100%;
    border: 1px solid transparent;
    margin: 0 -1px -1px;
    &.item_add {
        border-top: 1px solid #e7e7eb;
    }
    &.active {
        border: 1px solid #44b549;
        color: #44b549;
    }
    .item_top {
        border-top: 1px solid transparent;
    }
    span {
        display: block;
        border-top: 1px solid #e7e7eb;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        cursor: pointer;
    }
}
.add {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    display: inline-block;
    margin-top: -2px;
    background: url(../images/index_z354723.png) 0 -18px no-repeat;
}
</style>
