<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix">
            <el-button 
                @click="delet"
                v-show="idx== -1"
                style="float: right; padding: 3px 0" type="text">删除菜单</el-button>
            <el-button 
                v-show="idx !== -1"
                @click="deletitem"
                style="float: right; padding: 3px 0" type="text">删除子菜单</el-button>
        </div>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="菜单名称">
                <el-input v-model="form.name" @input="rename"></el-input>
            </el-form-item>
            <el-form-item label="菜单内容" v-if="form.type === 'view'">
                <el-radio v-model="form.content">跳转网页</el-radio>
            </el-form-item>
            <el-form-item label="页面地址" v-if="form.type === 'view'">
                <el-input v-model="form.url" @input="rename">
                </el-input>
            </el-form-item>
        </el-form>
    </el-card>
</template>

<script>
export default {
    data () {
        return {
            form: {
                name: '',
                content: '',
                url: '',
            }
        }
    },
    props: {
        content: {
            type:Object,
            default() {
                return {}
            }
        },
        group: {
            type:Number
        },
        idx: {
            type:Number
        }
    },
    methods: {
        rename() {
            console.log(this.form.name)
            this.$emit('charge',{form:this.form,index:this.idx,group:this.group})
        },
        delet() {
            this.$emit('remove',{form:this.form,index:this.idx,group:this.group})
        },
        deletitem() {
            console.log('删除子菜单')
            this.$emit('removeitem',{form:this.form,index:this.idx,group:this.group})
        }
    },
    watch:{
        content:{
            immediate:true,
            handler:function(val) {
                console.log(val)
                this.form = Object.assign({},val)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
.clearfix{
  button{
    height: 50px;
  }
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  padding-left: 20px;
  background-color: rgb(244,245,249);
  width: 480px;
  /* height: 580px; */
}
</style>